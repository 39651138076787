import '/@/components/Footer/Footer.scss'

const Footer = () => {
	return (
		<div className="footer">
			<div className="container">
				<div className="row">
					<div className="col-xl-7">
						<h4 className='text-center text-lg-start'>Telephelyeink</h4>
						<ul>
							<li>
								<a
									target="_blank"
									href="https://goo.gl/maps/EdjBKeuRo3UN7nrs6">
									2310 Szigetszentmiklos, Mester utca 6. Kertészcentrum Áruház
								</a>

								<a href="tel:+36304139252">+36 30 413 9252</a>
							</li>
							<li>
								<a
									target="_blank"
									href="https://goo.gl/maps/8ENYWZM3jPGAvQUx6">
									1112 Budapest, Budaörsi út 1092/6. hrsz. Flóracoop Virágpiac – Növészvédöszer üzlet
								</a>

								<a href="tel:+36209687090">+36 20 968 7090</a>

							</li>
							<li>
								<a
									target="_blank"
									href="https://goo.gl/maps/Ui95DVw2To4Kwjy59">
									2310 Szigetszentmiklos, Leshegy út 1/b. Flóra Hungária Virágpiac – Növényvédöszer üzlet
								</a>

								<a href="tel:+36705164286">+36 70 516 4286</a>
							</li>
						</ul>
					</div>
					<div className="col-xl-5">
						<h4 className='text-center text-lg-start'>Csapatunk</h4>
						<ul>
							<li>
								<span>
									Dr. Lammel Kálmánné
								</span>
								<a
									href="tel:+36209687090">
									+36 20 968 7090
								</a>
							</li>
							<li>
								<span>Dr. Mándoki Zoltán</span>
								<a
									href="tel:+36705164286">
									+36 70 516 4286
								</a>

								<a href="mailto:florachembt@gmail.com">florachembt@gmail.com</a>

							</li>
							<li>
								<span>Borkesz Richárd</span>
								<a
									href="tel:+36304139252">
									+36 30 413 9252
								</a>
								<a href="mailto:kerteszcentrumeu@gmail.com">kerteszcentrumeu@gmail.com</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="year">
					Florachem 1998 - {new Date().getFullYear()}
				</div>
			</div>
			{/* <div className="grass grass__top"></div> */}
			<div className="grass grass__bottom"></div>
		</div>
	)
}

export default Footer;

import { createContext, FC } from 'react';
import ResponsiveImg from '/@/components/ResponsiveImg/ResponsiveImg';
import DummyComponent from '/@/components/Dummy/DummyComponent';

type GlobalContext = {
	DummyComponent: FC<any>,
}

export const GlobalContextValue = {
	// ResponsiveImg,
	DummyComponent
};

export const GlobalContext = createContext<GlobalContext>(GlobalContextValue);



import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion, useCycle } from "framer-motion"
import assets from './assets';
import './Header.scss'


const navigationStates = {
	closed: {
		y: '-100%',
		transition: {
			duration: 0.5
		}
	},
	open: {
		y: 0,
		transition: {
			duration: 0.5
		}
	},
};

const title = import.meta.env.VITE_TITLE;

const Header = () => {

	// Hooks
	const location = useLocation();

	// States
	const [mobileNavigationOpen, toggleNavigationOpen] = useCycle<boolean>(false, true);
	const [mobileView, setmobileView] = useState<boolean>(false);

	// Effects
	useEffect(() => {
		if (mobileNavigationOpen) {
			document.querySelector('body')?.classList.add('overflow-hidden');
		} else {
			document.querySelector('body')?.classList.remove('overflow-hidden');
		}
	}, [mobileNavigationOpen]);

	useEffect(() => {
		window.addEventListener('resize', handleView);
	}, [location]);

	// Functions
	const handleView = () => {
		setmobileView(window.innerWidth <= 990);
	}

	useEffect(() => {
		document.title = title;
	  }, []);

	// Template
	return (
		<header className={mobileNavigationOpen ? 'open' : ''}>
			<div>
				<div className="container position-relative">
					<NavLink to="/" className="logo">
						<img className='florachem-logo' src={ assets.logo } alt="florachem-logo" />
						<span>{title}</span>
					</NavLink>
					<nav>
						{/* <a className={`d-lg-none hamburger ${mobileNavigationOpen ? 'open' : ''} `}
							onClick={() => toggleNavigationOpen()} >
							<span></span>
							<span></span>
							<span></span>
						</a> */}
						<ul>
							{/* <li>
								<NavLink to="/">
									Productions
								</NavLink>
							</li>
							<li>
								<NavLink to="/dummy">
									Dummy
								</NavLink>
							</li>
							<li>
								<NavLink to="/about">
									About
								</NavLink>
							</li> */}
						</ul>
					</nav>
				</div>
			</div>
			<motion.div
				className={'mobile-navigation ' + (mobileNavigationOpen ? 'open' : '') }
				initial="closed"
				animate={ mobileNavigationOpen ? 'open' : 'closed' }
				variants={navigationStates}>
				<div className="content">
					<div className="container">
						<ul>
							<li>
								<NavLink to="/">
									Productions
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</motion.div>
		</header>
	)
}

export default Header
